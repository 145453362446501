import React, { useRef } from 'react';
import axios from "axios"
import * as Yup from "yup"
import { Form } from "@unform/web"
import Input from '../components/input'
import Textarea from '../components/textarea'
import { FaTools } from "react-icons/fa"
import { FaGraduationCap } from "react-icons/fa"

import Layout from "../components/layout"

import SEO from "../components/seo"

const IndexPage = () => {

  const [alert, setAlert] = React.useState(false);

  const formRef = useRef(null);

  async function handleSubmit(data, { reset }) {
    
    try {
      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        name: Yup.string().required('Infome seu Nome'),
        email: Yup.string().email('O e-mail precisa ser válido').required('Informe seu E-mail'),
        message: Yup.string().required('Informe sua Mensagem'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const response = await axios.post('https://getform.io/f/b925fa08-a552-4e3a-9cde-5ccdd25d10ac', data);

      if (response.status == 200) {
        setAlert(true);
        reset();
      }
      
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }

  }

  return (
    <Layout>
      <SEO title="Home" description="Solucões em Asterisk " />
      <section id="services">
        <div className="container">
          <h1>Serviços</h1>
          
          <div className="services">
            <div className="box">
              <FaTools size={50} />
              <h2>Suporte Técnico</h2>
              <p>Suporte técnico expecializado em Asterisk</p>
            </div>
            <div className="box">
              <FaGraduationCap size={50} />
              <h2>Treinamentos</h2>
              <p>Cursos especializados em telefonia</p>
            </div>
          </div>
        </div>
      </section>

      <section id="contact" className="contact ligth-gradient-bg">
        
        <div className="container">
          <h1>Contato</h1>
          
          { alert && (
            <p>Formulário enviado com sucesso!</p>
          )}
          <Form ref={formRef} onSubmit={handleSubmit}> 
            <div className="form-control">
              <Input name="name" placeholder="Nome *" />
            </div>
            <div className="form-control">
              <Input name="email" placeholder="E-mail *" />
            </div>
            <div className="form-control">
              <Textarea name="message" rows="10" placeholder="Mensagem *" />
            </div>

            <button type="submit">Enviar</button>
          </Form>
        </div>
      </section>
      
      <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
        
      </div>
      
    </Layout>
  )
}

export default IndexPage
